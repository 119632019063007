<template>
    <el-dialog
      title="修改销售地区"
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"

      :close-on-click-modal="false"
    >
      <div v-for="(item,index) in data" :key="item.county" class="disflex">
        <span>销售地区{{index+1}}：</span>
        <el-cascader size="large" :options="options" v-model="item.data" @change="handleChange(index, arguments)" placeholder="请选择省市区" clearable></el-cascader>
      </div>
      <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button class="btncss" @click="confirmAddDealer">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {
    provinceAndCityData,
    regionData,
    provinceAndCityDataPlus,
    regionDataPlus,
    CodeToText,
    TextToCode
} from 'element-china-area-data'
// import commonTable from "@/components/common/commonTable";
import { fetchUpdateArea } from '@/api/customPage'
export default {
    components:{
      // commonTable
    },
    data() {
        return {
            visible: false,
            handle:'',
            data: [],
            options:regionData,
        }
    },
    methods:{
      openDialog(option){
        this.visible = true
        let arr = option.areas.map(item => {
          return {
            ...item,
            data: [item.province, item.city, item.county]
          }
        })
        this.data=arr
        this.handle = option.handle
      },
      closeDialog(option) {
        this.visible = false
      },
      handleChange(index, e) {
        let value = e[0]
        this.data[index] = {
          province: value[0],
          city: value[1],
          county: value[2],
          address: CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]],
          data: [value[0], value[1], value[2]]
        }
        delete this.data[index].id
      },
      confirmAddDealer() {
        let params={
          handle: this.handle,
          areas: this.data
        }
        fetchUpdateArea(params).then(res => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.closeDialog()
          this.$emit('refresh')
        })
      }

    }
}
</script>
<style scoped>
.dialog-footer{
  display: flex;
  justify-content: center;
}
.disflex{
  display: flex;
  align-items: center;
}
.btncss{
  background: #51CDCB;
  color: #fff;
  border-color: #51CDCB;
}
</style>

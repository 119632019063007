<template>
    <el-dialog
      title=""
      width="900px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <commonTable
          :tableData="data"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <template v-slot:table>
          <el-table-column prop="handle" align="center" label="标识" width="180">
          </el-table-column>
          <el-table-column prop="avatarUrl" align="center" label="头像">
            <template slot-scope="scope">
              <img class="avatar" :src="scope.row.avatarUrl" />
            </template>
          </el-table-column>

          <el-table-column prop="number" align="center" label="查询次数">
            <template slot-scope="scope">
              第<span class="blueText">{{scope.row.number}}</span>次查询
            </template>
          </el-table-column>
          <el-table-column prop="number" align="center" label="浏览时长">
            <template slot-scope="scope">
                <template v-if="scope.row.seeTime">
                  <span class="time_span" :class="getTimeColor(scope.row.seeTime)"> 
                    {{ getTimeText(scope.row.seeTime)}}
                  </span>
                </template>
                <template v-else>
                  ---
                </template>
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="扫码地址" width='250'>
             <template slot-scope="scope">
             {{ scope.row.address ? scope.row.address : '---'}}
             </template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="扫码时间" width="180">
          </el-table-column>
        </template>

      </commonTable>
    </el-dialog>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { fetchOneScanLog } from '@/api/customPage'
export default {
    components:{ commonTable },
    data() {
        return {
            visible: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
            handle:''
        }
    },
    methods:{
      getTimeColor(time){
          let colorArr = ['grey','blue','orange']
          if(time && time < 60){
              return colorArr[0]
          }else if(time && time >= 60 && time < 600 ) {
              return colorArr[1]
          }else if(time >= 600) {
              return colorArr[2]
          }
      },
      getTimeText(time) {
        if(time && time < 600){
          return time + 's'
        }else if(time >= 600) {
          return '600+'
        }else {
          return '-'
        }
      },
      openDialog(option){
        if(option.handle) {
          this.handle= option.handle
          this.getList()
        }
        this.visible = true
      },
      handleClose() {
       this.visible = false
      },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      getList() {
          let params ={
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            handle: this.handle
          }
          fetchOneScanLog(params).then(res => {
            this.data =  res.data.list
            this.total = res.data.total
          })
      }
    }
}
</script>
<style scoped>
.avatar{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.orange{
      background-color: #FFAB52;
      color: #fff;
}
.blueText{
  color: #4A7AF0;
}
</style>

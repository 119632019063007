<template>
  <div>
    <el-dialog
      title="数据回读"
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
     <el-form>
       <el-form-item label="批次号">
         {{ lotNumber }}
       </el-form-item>
       <el-form-item label="文件">
          <el-upload
          class="upload-demo myUpload"
          :action="uploadUrl"
          :headers="upParams"
          :data="upData"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
           multiple
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传TXT文件</div>
        </el-upload>        
       </el-form-item>

     </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { fetchPageData } from '@/api/customPage.js'
let token = localStorage.getItem("token")
// let baseUrl = 'http://10.10.0.79:8667'
let baseUrl = 'https://scrm.86122m.com/api'
export default {
  components:{
  },
  props: ['lotNumber'],
  data(){
    return {
      visible: false,
      fileList: [],
      upParams: {
				token: token,
        // "Content-Type": "application/json"
      },
      upData: {
        lotNumber: this.lotNumber,
      },
      uploadUrl: baseUrl +'/customPage/importRecord'
    }
  },
  computed: {
  },
  created(){
    this
  },
  methods:{
    init(data) {
      this.tableData = data
    },
    openDialog() {
      this.visible = true
    },
    async getPageData() {
      const result = await fetchPageData(this.pageId)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess(response, file, fileList) {
      if(response.code === 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.visible = false
        this.fileList=[]
      }else {
        this.fileList = []
        this.$message({
          message: response.message,
          type: "error",
        });
      }
    },
    handleError(err, file, fileList) {
    }
  }
  
}
</script>
<style scoped>
.tip{
  color: #f00;
  line-height: 30px;
}
.cell_span{
  display: inline-block;
  text-align: center;
  width: 100%;
}
/deep/ .myUpload .el-upload--text{
  width: auto;
  height: auto;
}
</style>
<template>
    <el-dialog
      title=""
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <commonTable
          :tableData="data"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <template v-slot:table>
          <el-table-column prop="handle" align="center" label="标识">
          </el-table-column>
          <el-table-column prop="title" align="center" label="名称">
          </el-table-column>
          <el-table-column prop="lotNumber" align="center" label="批次号">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间">
          </el-table-column>
        </template>
      </commonTable>
    </el-dialog>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { fetchBindProList } from '@/api/customPage'
export default {
    components:{ commonTable },
    data() {
        return {
            visible: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            boxHandle: '',
            data: [],
        }
    },
    methods:{
      openDialog(option){
        if(option.handle) {
          this.boxHandle= option.handle
          this.getList()
        }
        this.visible = true
      },
      handleClose() {
       this.visible = false
      },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      getList() {
          let params ={
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            boxHandle: this.boxHandle
          }
          fetchBindProList(params).then(res => {
            this.data =  res.data.list
            this.total = res.data.total
          })
      }
    }
}
</script>
